import React, { Component } from "react";
import {
  Dimmer,
  Grid,
  GridColumn,
  Image,
  Loader,
  GridRow,
  Button,
  Checkbox,
  Popup,
  Icon,
} from "semantic-ui-react";
import * as logo from "../../../assets/logo_white.png";
import * as placeholderMale from "../../../assets/male.jpg";
import * as placeholderFemale from "../../../assets/female.jpg";
import * as placeholderMaleActive from "../../../assets/male-active.png";
import * as placeholderFemaleActive from "../../../assets/female-active.png";
// import * as placeholderMale from '../../../assets/male.jpg';
// import * as placeholderFemale from '../../../assets/female.jpg';
// import * as placeholderMaleActive from '../../../assets/male-active.png';
// import * as placeholderFemaleActive from '../../../assets/female-active.png';
import RangeSlider from "../general/rangeSlider";
import DropdownSelect from "../general/dropdownSelect";
import axios from "axios";
import * as routes from "../constants/api_routes";

const genders = [
    {
        key: 1,
        text: 'Female',
        value: 'female'
    },
    {
        key: 2,
        text: 'Male',
        value: 'male'
    },
    {
        key: 3,
        text: 'Other',
        value: 'other'
    },
    {
        key: 6,
        text: 'Prefer not to say',
        value: 'prefer_not_to_say'
    }
]

class AboutYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      selectedGender: null,
      selectedAge: null,
      selectedSalaryRange: null,
      salaryRangeValue: null,
      selectedSector: null,
      imageLoaded: false,
      selectedSalaryRangeLabel: null,
      sectorOptions: [],
      isOrganisation: false,
    };
    if (localStorage.getItem("organisation")) {
      this.organisation = JSON.parse(localStorage.getItem("organisation"));
    } else {
      this.organisation = undefined;
    }
  }

  componentWillMount = async () => {
    if (this.organisation) {
      this.setState({
        selectedSector: this.organisation.sector_id,
        isOrganisation: true,
      });
    } else {
      this.setState({ loader: true });
      let sectorOptions = [];
      const response = await axios.get(`${routes.GET_SECTORS}`);
      if (response.data) {
        let sectorList = response.data;
        for (let i in sectorList) {
          sectorOptions.push({
            key: sectorList[i].id,
            text: sectorList[i]["sector_name"],
            value: sectorList[i].id,
          });
        }
        this.setState({ sectorOptions: sectorOptions });
      }
      this.setState({ loader: false });
    }
  };

  handleSelectForAge = (value) => {
    this.setState({ selectedAge: value });
  };

  handleSelectForSalaryRange = (value) => {
    let salaryRangeValue = "";
    if (value >= 0 && value <= 10) {
      salaryRangeValue = "Up to £10,000 a year";
    }
    if (value >= 11 && value <= 20) {
      salaryRangeValue = "£10,001 to £20,000 a year";
    }
    if (value >= 21 && value <= 30) {
      salaryRangeValue = "£20,001 to £30,000 a year";
    }
    if (value >= 31 && value <= 40) {
      salaryRangeValue = "£30,001 to £40,000 a year";
    }
    if (value >= 41 && value <= 50) {
      salaryRangeValue = "£40,001 to £50,000 a year";
    }
    if (value >= 51 && value <= 60) {
      salaryRangeValue = "£50,001 to £60,000 a year";
    }
    if (value >= 61 && value <= 70) {
      salaryRangeValue = "£60,001 to £70,000 a year";
    }
    if (value >= 71 && value <= 80) {
      salaryRangeValue = "£70,001 to £80,000 a year";
    }
    if (value >= 81 && value <= 90) {
      salaryRangeValue = "£80,001 to £90,000 a year";
    }
    if (value >= 91 && value <= 100) {
      salaryRangeValue = "£90,001 to £100,000 a year";
    }
    if (value >= 100) {
      salaryRangeValue = "Over £100,001 a year";
    }
    if (value === 500) {
      salaryRangeValue = "Prefer not to say";
      value = null;
    }
    this.setState({
      selectedSalaryRangeLabel: salaryRangeValue,
      selectedSalaryRange: value,
    });
  };

  handleSelectForSector = (event, data) => {
    this.setState({ selectedSector: data.value });
    if (!this.state.selectedGender) {
      this.setState({ showSelectGenderClue: true });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loader: true });
    let resultData = {};
    if(localStorage.getItem('organisation')){
      resultData.organisation_id = JSON.parse(localStorage.getItem('organisation'))['id'];
    };
    resultData.gender = this.state.selectedGender;
    resultData.age = this.state.selectedAge;
    resultData["salary_range"] = this.state.selectedSalaryRangeLabel;
    resultData.sector_id = this.state.selectedSector;
    const response = await axios.post(`${routes.START_SURVEY}`, resultData);

    if (response.status === 200) {
      const surveyData = response.data;
      localStorage.setItem("survey_id", surveyData.id);
      this.props.history.replace("/get-wellbeing-score");
    }
  };

  render() {
    return (
      <div>
        <Dimmer active={this.state.loader}>
          <Loader size="large">Loading</Loader>
        </Dimmer>
        <Grid className="section-main-div about-you">
          <GridColumn width={3} className="section-details">
            <Image
              src={
                this.organisation &&
                this.organisation.logo &&
                this.organisation.logo.length > 2
                  ? this.organisation.logo
                  : logo
              }
            />
            <div className="tell-us-section">
              Tell us a little about yourself
            </div>
          </GridColumn>
          <GridColumn width={13} className="lens-div">
            <div className="lens">
              <Image
                src="/web_lens_full.png"
                onLoad={() => {
                  this.setState({ imageLoaded: true });
                }}
              />
            </div>
            <div className="about-you-content">
              <Grid className="other-info">
                <GridRow>
                  <RangeSlider
                    step={1}
                    label="Age"
                    selectedRangeLabel={this.state.selectedAge}
                    selectedAnswer={this.state.selectedAge}
                    handleSelect={this.handleSelectForAge}
                    min={16}
                    max={100}
                    labels={{ 0: "16", 100: "100" }}
                    tooltip="This information is used for analysis and comparative purposes only; your answers won’t change the questions you will be asked."
                  />
                </GridRow>
                <GridRow>
                  <GridColumn
                    width={4}
                    style={{ paddingLeft: 0, paddingTop: "5px" }}
                    textAlign="left"
                  >
                    <div className="heading" style={{ position: "relative" }}>
                      Gender{" "}
                      <Popup
                        trigger={
                          <Icon circular name="info" className="info-popup" />
                        }
                        content="This information is used for analysis and comparative purposes only; your answers won’t change the questions you will be asked."
                        size="small"
                      />
                    </div>
                  </GridColumn>
                  <GridColumn
                    width={4}
                    className="gender-select"
                    style={{ margin: "0 auto", left: 0, right: 0 }}
                  >
                    <Image
                      src={
                        this.state.selectedGender === "male"
                          ? placeholderMaleActive
                          : placeholderMale
                      }
                      onClick={() => {
                        this.setState({
                          selectedGender: "male",
                          showSelectGenderClue: false,
                        });
                      }}
                      centered
                      className={
                        this.state.selectedGender === "male" ? "active" : ""
                      }
                    />
                    {this.state.selectedGender === "male" && (
                      <Button circular icon="checkmark" />
                    )}
                  </GridColumn>
                  <GridColumn
                    width={4}
                    className="gender-select"
                    style={{ margin: "0 auto" }}
                  >
                    <Image
                      src={
                        this.state.selectedGender === "female"
                          ? placeholderFemaleActive
                          : placeholderFemale
                      }
                      onClick={() => {
                        this.setState({
                          selectedGender: "female",
                          showSelectGenderClue: false,
                        });
                      }}
                      centered
                      className={
                        this.state.selectedGender === "female" ? "active" : ""
                      }
                    />
                    {this.state.selectedGender === "female" && (
                      <Button circular icon="checkmark" />
                    )}
                  </GridColumn>
                  <GridColumn width={4} />
                </GridRow>
                <GridRow
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <GridColumn width={4}></GridColumn>
                  <GridColumn width={4} style={{ textAlign: "center" }}>
                    <span>Male</span>
                  </GridColumn>
                  <GridColumn width={4} style={{ textAlign: "center" }}>
                    <span>Female</span>
                  </GridColumn>
                  <GridColumn width={4}></GridColumn>
                </GridRow>
                <GridRow>
                  <RangeSlider
                    selectedRangeLabel={this.state.selectedSalaryRangeLabel}
                    step={10}
                    max={110}
                    label="Salary range"
                    selectedAnswer={this.state.selectedSalaryRange}
                    handleSelect={this.handleSelectForSalaryRange}
                    tooltip="This information is used for analysis and comparative purposes only; your answers won’t change the questions you will be asked."
                  />
                  <Checkbox
                    label="Prefer not to say"
                    checked={
                      this.state.selectedSalaryRangeLabel ===
                      "Prefer not to say"
                    }
                    style={{ marginLeft: "10px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        this.state.selectedSalaryRangeLabel !==
                        "Prefer not to say"
                      ) {
                        this.setState({
                          selectedSalaryRangeLabel: "Prefer not to say",
                          selectedSalaryRange: null,
                        });
                      }
                    }}
                  />
                </GridRow>
                {this.state.isOrganisation === false && (
                  <GridRow style={{ paddingTop: "5%" }}>
                    <div className="heading"><span>Sector</span>{" "}<span><Popup
                        trigger={
                          <Icon circular name="info" className="info-popup" />
                        }
                        content="This information is used for analysis and comparative purposes only; your answers won’t change the questions you will be asked."
                        size="small"
                      /></span></div>
                    <DropdownSelect
                      options={this.state.sectorOptions}
                      handleSelect={this.handleSelectForSector}
                      search={true}
                    />
                  </GridRow>
                )}
                <GridRow className="submit-button-div">
                  <Button
                    disabled={
                      !(
                        this.state.selectedGender &&
                        this.state.selectedAge &&
                        this.state.selectedSalaryRangeLabel &&
                        this.state.selectedSector
                      )
                    }
                    style={{ float: "right" }}
                    primary
                    onClick={this.handleSubmit}
                  >
                    {" "}
                    Let's get started
                  </Button>
                </GridRow>
              </Grid>
            </div>
          </GridColumn>
        </Grid>
      </div>
    );
  }
}

export default AboutYou;
